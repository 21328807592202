import {ConfigProvider, Layout} from 'antd';
import frFR from 'antd/lib/locale/fr_FR';
import 'core-js/stable';
import {FC, useContext} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import 'regenerator-runtime/runtime';
import './App.less';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Home/Home';
import Login from './components/Login';
import Orders from './components/Orders/Orders';
import DeliveryRequest from './components/Request/Delivery';
import InvoiceRequest from './components/Request/Invoice';
import OrderForm from './components/Request/OrderForm';
import Sales from './components/Sales';
import {SessionContext, SessionProvider} from './components/Session';
import Settings from './components/Settings';
import Stores from './components/Stores';
import TechnicalDocuments from './components/TechnicalDocuments';
import Catalogues from './components/Catalogues';

const {Content} = Layout;

const App: FC = () => {
  if (!process.env.REACT_APP_API_HOST) {
    throw new Error('Environment variable REACT_APP_API_HOST is not set');
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [session] = useContext(SessionContext);

  const pathName = window.location.pathname;
  if (!session?.token && !pathName.startsWith('/login')) {
    const token = new URLSearchParams(
      window.location.search.replace(/^\?/, ''),
    ).get('auth');

    // @ts-ignore
    window.location = `/login${
      token ? `/${token}` : ''
    }?redirect=${encodeURIComponent(pathName)}`;

    return null;
  }

  return (
    <SessionProvider>
      <ConfigProvider locale={frFR}>
        <Router>
          <Layout style={{minHeight: '100vh', backgroundColor: 'white'}}>
            <Header />
            {/* <HeaderMenu /> */}
            <Content
              style={{
                textAlign: 'center',
                maxWidth: '1200px',
                margin: 'auto',
              }}
            >
              <Switch>
                <Route path="/login/:authToken?">
                  <Login />
                </Route>
                <Route path="/settings">
                  <Settings />
                </Route>
                <Route path="/stores">
                  <Stores />
                </Route>
                <Route path="/orders">
                  <Orders />
                </Route>
                <Route path="/contact">
                  <Contact />
                </Route>
                <Route path="/sales">
                  <Sales />
                </Route>
                <Route path="/catalogues">
                  <Catalogues />
                </Route>
                <Route path="/technical-documents">
                  <TechnicalDocuments />
                </Route>
                <Route path="/request/invoice">
                  <InvoiceRequest />
                </Route>
                <Route path="/request/delivery">
                  <DeliveryRequest />
                </Route>
                <Route path="/request/order">
                  <OrderForm />
                </Route>
                <Route path="/">
                  <Home />
                </Route>
              </Switch>
            </Content>
            <Footer />
          </Layout>
        </Router>
      </ConfigProvider>
    </SessionProvider>
  );
};

export default App;
