import {Button, Card, Carousel, Col, Row, Spin} from 'antd';
import Title from 'antd/lib/typography/Title';
import {FC, useCallback, useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {useAsync} from '../../hooks/useAsync';
import {fetchApi, newApiUrl, openLoginUrl} from '../../util';
import {FileProps, getFileUrl} from '../FileTable';
import {SessionContext} from '../Session';
import Pitch from './Pitch';

const {REACT_APP_OFFLINE_MODE, NODE_ENV} = process.env;

const offlineCarouselUrls: FileProps[] = [
  'accueil-offre-exemple-1',
  'accueil-offre-exemple-2',
].map((name) => ({
  name: name,
  size: 12121230,
  url: `/assets/2022/${name}.png`,
  mimeType: 'image/png',
}));

const folder = 'carrousel';

const Home: FC = () => {
  const [session] = useContext(SessionContext);
  const [loading, setLoading] = useState(false);

  const handleStoreClick = async () => {
    if (!session) {
      return;
    }

    setLoading(true);
    await openLoginUrl('accessoire', session);
    setLoading(false);
  };

  const req = useCallback(async () => {
    if (REACT_APP_OFFLINE_MODE) return offlineCarouselUrls ?? [];
    const url = newApiUrl(`/files/list/${folder}`);
    const res = await fetchApi({session, url});
    return (await res.json()) as FileProps[];
  }, [session]);
  const {value: carouselUrls} = useAsync(req);

  return (
    <>
      <Pitch />
      <div style={{marginBottom: '30px'}} className="home">
        <Row>
          <Col span={24}>
            <Carousel
              autoplay={true}
              style={{maxWidth: '100vw'}}
              autoplaySpeed={10000}
            >
              {carouselUrls ? (
                carouselUrls?.map(({name}, index) => (
                  <div key={index}>
                    <img
                      src={getFileUrl(name, folder).toString()}
                      alt="Offre"
                      style={{maxWidth: '100%'}}
                    />
                  </div>
                ))
              ) : (
                <Spin />
              )}
            </Carousel>
          </Col>
          <Col span={24} lg={8}>
            <Link to="/stores">
              <Card
                className="home-card"
                style={{
                  border: 'none',
                  minHeight: '100%',
                  color: 'white',
                }}
              >
                <div className="background">
                  <div className="background-filter" />
                  <img
                    alt="IP3D logo"
                    src="/assets/2022/accueil-embouts-2.jpg"
                  />
                </div>
                <div className="foreground">
                  <Title>Embouts de prothèses &amp; anti-bruit</Title>
                </div>
                <Button type="primary">Je commande !</Button>
              </Card>
            </Link>
          </Col>
          <Col span={24} lg={8}>
            <Card
              className="home-card"
              style={{
                border: 'none',
                minHeight: '100%',
                cursor: 'pointer',
              }}
              onClick={handleStoreClick}
            >
              <div className="background">
                <div className="background-filter" />
                <img
                  alt="Accessoire logo"
                  src="/assets/2022/accueil-accessoires-2.jpg"
                />
              </div>
              <div className="foreground">
                <Title>Accessoires</Title>
              </div>
              <Button type="primary" loading={loading}>
                Je commande !
              </Button>
            </Card>
          </Col>

          <Col span={24} lg={8}>
            <div className="half">
              <Link to="/catalogues">
                <Card
                  className="home-card"
                  style={{
                    border: 'none',
                    minHeight: '100%',
                  }}
                >
                  <div className="background">
                    <div className="background-filter" />
                    <img
                      alt="Catalogue logo"
                      src="/assets/2022/accueil-catalogue-2.png"
                    />
                  </div>
                  <div className="foreground">
                    <Title>Catalogues</Title>
                  </div>
                </Card>
              </Link>
            </div>
            <div className="half">
              <Link to="/technical-documents">
                <Card
                  className="home-card"
                  style={{
                    border: 'none',
                    minHeight: '100%',
                  }}
                >
                  <div className="background">
                    <div className="background-filter" />
                    <img
                      style={{position: 'absolute', left: 0, top: '-135px'}}
                      alt="Fiche techniques logo"
                      src="/assets/2022/accueil-brochures.jpg"
                    />
                  </div>
                  <div className="foreground">
                    <Title>Fiches techniques &amp; tutos</Title>
                  </div>
                </Card>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Home;
