import qs from 'qs';
import {Session} from './components/Session';

/** fetch with timeOut option */
export const timeoutableFetch = (
  input: RequestInfo,
  init?: RequestInit & {timeOutMs?: number},
) => {
  let {timeOutMs = 30 * 1000, ...rest} = init ?? {};
  if (rest.signal) {
    throw new Error('Signal not supported in timeoutableFetch');
  }

  const controller = new AbortController();
  const {signal} = controller;

  return new Promise<Response>((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error('Request timed out'));
      controller.abort();
    }, timeOutMs);
    fetch(input, {signal, ...rest})
      .finally(() => clearTimeout(timer))
      .then(resolve, reject);
  });
};

/** creates an URL object from an endpoint and optional parameters */
export const newApiUrl = (endpoint: string, searchParams?: any): URL => {
  const url = new URL(`${process.env.REACT_APP_API_HOST}${endpoint}`);
  if (searchParams) {
    const str = qs.stringify(searchParams);
    url.search = str;
  }
  return url;
};

export const fetchApi = (options: {
  session?: Session;
  url?: URL;
  endpoint?: string;
  headers?: RequestInit['headers'];
  method?: RequestInit['method'];
  body?: RequestInit['body'];
  timeOutMs?: number;
}) =>
  (options.timeOutMs ? timeoutableFetch : fetch)(
    options.url?.toString() ??
      `${process.env.REACT_APP_API_HOST}${options.endpoint}`,
    {
      headers: {
        Authorization: `Bearer ${options.session?.token ?? ''}`,
        'Content-Type': 'application/json; charset=utf-8',
        ...options.headers,
      },
      credentials: 'include',
      method: options.method,
      body: options.body,
      ...(options.timeOutMs ? {timeOutMs: options.timeOutMs} : undefined),
    },
  );

export type StoreType =
  | 'intersonProtac'
  | 'surdifuse'
  | 'emboutFrancais'
  | 'accessoire';

const {REACT_APP_OFFLINE_MODE} = process.env;

export const getLoginUrl = async (
  type: StoreType,
  session: Session,
): Promise<string> => {
  if (REACT_APP_OFFLINE_MODE) {
    const demoToken = 'FFckUayWXEmashftbVtgbUnUxuaFuwwGSZQyrmNr';
    switch(type) {
      case 'intersonProtac':
        return 'https://demo-nimes-store-interson-protac.logicgram.fr/login/' + demoToken;
      case 'surdifuse':
        return 'https://demo-paris-store-interson-protac.logicgram.fr/login/' + demoToken;
      case 'emboutFrancais':
        return 'https://demo-lyon-store-interson-protac.logicgram.fr/login/' + demoToken;
      case 'accessoire':
        return 'https://store.interson-protac.com:4443/#!/login/b69db4b85787b4e5109b77eeb873888e89cc7e746931a8d192747d320cc666b7ff4a88dec7c77998';
    }
  }

  const res = await fetchApi({
    session,
    url: newApiUrl(`/session/getLoginToken/${type}`),
  });

  return (await res.json()).url;
};

export const openLoginUrl = async (
  type: StoreType,
  session: Session,
): Promise<void> => {
  const url = await getLoginUrl(type, session);
  if (!url) return;

  Object.assign(document.createElement('a'), {
    target: '_blank',
    href: url,
    rel: 'norel noopener',
  }).click();
};

export const openOrderUrl = async (
  ID: string,
  type: StoreType,
  session: Session,
): Promise<void> => {
  if (REACT_APP_OFFLINE_MODE) {
    alert('En cours de développement');
    return;
  }

  const url = await getLoginUrl(type, session);
  if (!url) return;

  Object.assign(document.createElement('a'), {
    target: '_blank',
    href: `${url}?action=viewOrder&ID=${ID}`,
    rel: 'norel noopener',
  }).click();
};
