import {DownloadOutlined} from '@ant-design/icons';
import {Button, Col, Row, Typography} from 'antd';
import React, {FC} from 'react';
import Pitch from '../Home/Pitch';
import {storesData} from '../Stores';

const {Title} = Typography;

const OrderForm: FC = () => {
  return (
    <>
      <Pitch background="Mon espace" foreground="Bon de commande" />
      <Title
        level={4}
        style={{
          color: 'black',
          marginBottom: '25px',
          textTransform: 'uppercase',
          letterSpacing: '3px',
        }}
      >
        Quel bon de commande souhaitez-vous télécharger ?
      </Title>
      <Row gutter={30} justify="center" style={{textAlign: 'center'}}>
        {storesData.map(({humanName, path, imageKey}, index) => (
          <Col key={index} className="store-card">
            <div className="background">
              <img
                alt={humanName}
                src={`/assets/2022/embouts-${imageKey}-hover.jpg`}
                style={{
                  padding: '5px',
                }}
              />
            </div>
            <div className="foreground">
              <img
                alt={`Logo ${humanName}`}
                src={`/assets/2022/${imageKey}.svg`}
              />
              <div>{humanName}</div>
            </div>
            <a
              href={`/assets/order-form/${path}.pdf`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="primary">
                <DownloadOutlined /> Télécharger
              </Button>
            </a>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default OrderForm;
