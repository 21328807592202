import {Alert, Button, Checkbox, Form, Input, Row, Typography} from 'antd';
import {FC, useContext, useState} from 'react';
import {Redirect, useParams} from 'react-router-dom';
import {usePutSession, useResetUserPassword} from '../api';
import {getApiExceptionData, isApiException} from '../api-helpers';
import {SessionContext} from './Session';

const {Title} = Typography;

const {
  REACT_APP_DEFAULT_USERNAME,
  REACT_APP_DEFAULT_PASSWORD,
  REACT_APP_OFFLINE_MODE,
} = process.env;

const printableErrors: {[key: string]: string} = {
  'user not found': "L'utilisateur n'existe pas",
  'user password is invalid': 'Le mot de passe est invalide',
  'Failed to fetch: Failed to fetch': 'Impossible de se connecter au serveur',
};

export const getErrorString = (error: any) => {
  let message = '';
  if (isApiException(error)) {
    const data = getApiExceptionData(error);
    message = data.message;
  } else {
    message = error.message;
  }
  return printableErrors[message] ?? message;
};

const layout = {};
const tailLayout = {
  wrapperCol: {span: 16},
};

interface LoginFormValues {
  username: string;
  password: string;
  remember: boolean;
}

interface LoginParams {
  authToken?: string;
}

const Login: FC = () => {
  let {authToken} = useParams<LoginParams>();

  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [session, setSession] = useContext(SessionContext);
  const {mutate: login, loading: loadingLogin} = usePutSession({});
  const {
    mutate: resetPassword,
    loading: loadingResetPassword,
  } = useResetUserPassword({});
  const [resetMode, setResetMode] = useState(false);
  const [attemptedTokenLogin, setAttemptedTokenLogin] = useState(false);

  const onFinish = async ({username, password, remember}: LoginFormValues) => {
    if (REACT_APP_OFFLINE_MODE) {
      setSession({
        token: 'OFFLINE_TOKEN',
        user: {
          id: -1,
          uuid: '-',
          code: 'OFFLINE',
          email: 'offline@example.com',
          identifiant: 'offline@example.com',
          id_accessoire: '-1',
          id_emboutFrancais: '-1',
          id_intersonProtac: '-1',
          id_surdifuse: '-1',
          raisonSociale: 'OFFLINE',
        },
        remember,
      });

      return;
    }

    try {
      if (resetMode) {
        await resetPassword({login: username});
        setSuccess(
          'Si votre compte existe, votre mot de passe a été réinitialisé et vous a été communiqué par email.',
        );
        setResetMode(false);
      } else {
        const response = await login({login: username, password: password});
        // @ts-ignore
        setSession({...response, remember});
      }
    } catch (error) {
      setError(getErrorString(error));
      setSuccess('');
    }
  };

  if (authToken && !attemptedTokenLogin) {
    setAttemptedTokenLogin(true);
    console.log('attempting token login', {
      attemptedTokenLogin,
      authToken,
    });

    (async () => {
      const [username, password] = [
        authToken.substr(0, 40),
        authToken.substr(40, 40),
      ];
      onFinish({username, password, remember: true});
    })();
  }

  if (session?.token !== undefined) {
    const redirect = new URLSearchParams(
      window.location.search.replace(/^\?/, ''),
    ).get('redirect');

    return <Redirect to={redirect ?? '/'} />;
  }

  return (
    <div className="login-container">
      {error ? (
        <Alert
          message="Erreur de connexion"
          description={error}
          type="error"
          closable
          style={{marginBottom: 20, width: '100%'}}
          onClose={() => setError('')}
        />
      ) : null}
      {success ? (
        <Alert
          message="Succès"
          description={success}
          type="success"
          closable
          style={{marginBottom: 20, width: '100%'}}
          onClose={() => setSuccess('')}
        />
      ) : null}

      <div className="form-container">
        <div className="background-container">
          <img
            src="/assets/2022/connexion(1).jpg"
            alt="Fond connexion"
            style={{maxWidth: '1200px'}}
          />
        </div>

        <Row>
          <Form<LoginFormValues>
            {...layout}
            name="basic"
            className="login-form"
            initialValues={{remember: true}}
            onFinish={onFinish}
            style={{zIndex: 1}}
          >
            <Title style={{textTransform: 'uppercase'}} level={1}>
              Connexion
            </Title>
            <Title
              style={{textTransform: 'uppercase', paddingLeft: '5px'}}
              level={2}
            >
              À votre Espace Pro
            </Title>

            <div style={{marginTop: '30px', maxWidth: 'calc(100vw - 20px)'}}>
              <Form.Item
                label="Identifiant"
                name="username"
                initialValue={
                  REACT_APP_DEFAULT_USERNAME ??
                  (REACT_APP_OFFLINE_MODE ? 'offline@example.com' : '')
                }
                rules={[
                  {
                    required: true,
                    message: 'Veuillez entrer votre identifiant',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              {resetMode ? null : (
                <>
                  <Form.Item
                    label="Mot de passe"
                    name="password"
                    initialValue={
                      REACT_APP_DEFAULT_PASSWORD ??
                      (REACT_APP_OFFLINE_MODE ? 'password' : '')
                    }
                    rules={[
                      {
                        required: true,
                        message: 'Veuillez entrer votre mot de passe',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    {...tailLayout}
                    name="remember"
                    valuePropName="checked"
                  >
                    <Checkbox>Se souvenir de moi</Checkbox>
                  </Form.Item>
                </>
              )}

              <Form.Item {...tailLayout} style={{textAlign: 'left'}}>
                <div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loadingLogin || loadingResetPassword}
                    style={{textTransform: 'uppercase'}}
                  >
                    {resetMode ? 'Réinit. mot de passe' : 'Se connecter'}
                  </Button>
                </div>
                <div>
                  <Button
                    style={{marginTop: '5px', padding: '0'}}
                    htmlType="button"
                    onClick={() => setResetMode(!resetMode)}
                    type="link"
                  >
                    {resetMode ? 'Se connecter' : 'Mot de passe oublié ?'}
                  </Button>
                </div>
              </Form.Item>
            </div>
          </Form>
        </Row>
      </div>
    </div>
  );
};

export default Login;
