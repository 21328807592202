import {useRef, useCallback, useEffect} from 'react';

/** hook that keeps track of whether a component is mounted or unmounted. */
export const useIsMounted = () => {
  const ref = useRef(false);
  const getIsMounted = useCallback(() => ref.current, []);

  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);

  return getIsMounted;
};
