import {ApiError} from './api';
import {GetDataError} from 'restful-react';

export function isApiException(
  error: Error | GetDataError<ApiError>,
): error is GetDataError<ApiError> {
  return (error as GetDataError<ApiError>).data !== undefined;
}

const i18nMap: {[key: string]: {name: string; feminine?: boolean}} = {};

export const parseApiException = (data: ApiError): ApiError => {
  const {message = ''} = data;
  if (message.startsWith('FK')) {
    const table = message.split('_')[1];
    const {name, feminine} = i18nMap[table];
    data.message = `Au moins un${feminine ? 'e' : ''} ${name} est associé${
      feminine ? 'e' : ''
    }`;
  }

  return data;
};

// see https://github.com/contiamo/restful-react/issues/234
export const getApiExceptionData = (
  error: GetDataError<ApiError>,
): ApiError => {
  if (typeof error.data === 'string') {
    return {
      message: error.data ? error.data : error.message,
    };
  }
  return parseApiException(error.data);
};
