import {Button, Col, Layout, Row, Typography} from 'antd';
import React, {FC, useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {openLoginUrl} from '../util';
import {SessionContext} from './Session';

const {Footer: AntFooter} = Layout;
const {Title} = Typography;

const Footer: FC = () => {
  const [session] = useContext(SessionContext);
  const [loading, setLoading] = useState(false);

  if (!session) {
    return null;
  }

  const handleStoreClick = async () => {
    if (!session) {
      return;
    }

    setLoading(true);
    await openLoginUrl('accessoire', session);
    setLoading(false);
  };

  /* if (!session?.token && window.location.pathname !== '/login') {
    // @ts-ignore
    window.location = '/login';
    return null;
  } */

  return (
    <AntFooter
      style={{
        backgroundColor: '#202328',
        color: '#7D7B81',
        padding: '20px 60px',
        width: '100%',
        minHeight: '120px',
        bottom: '0',
        zIndex: 1,
        // position: 'absolute',
      }}
    >
      <Row
        gutter={15}
        justify="space-around"
        style={{textAlign: 'left', lineHeight: '10px'}}
      >
        <Col span={3}>
          <Link to="/">
            <img
              src="/logo/IntersonProtacbyProdways_transparent.png"
              alt="Logo Interson by Prodways"
              style={{
                maxWidth: '100%',
                position: 'relative',
                top: '18px',
              }}
            />
          </Link>
        </Col>
        <Col
          span={21}
          style={{textAlign: 'left', lineHeight: '17px', margin: '5px 0'}}
        >
          <b>
            CHEZ INTERSON BY PROD<span style={{color: '#dd3214'}}>WAYS</span>
            ...
          </b>
          <br />
          Le bruit est et a toujours été notre ennemi n°1. Présent dans chacun
          des moments de la vie, à la maison comme au travail, il vient nuire et
          gâcher ces instants si précieux. &nbsp;
          <u>Chez Interson Protac, nous ne pouvons laisser cela se produire.</u>
          &nbsp; C'est pourquoi, chacun de nos collaborateurs œuvrent, chaque
          jour, à vous proposer le meilleur de l'embout de prothèse et des
          protections auditives sur mesure afin que vous puissiez profiter de
          vos activités sans avoir a vous soucier du bruit et de son impact sur
          votre santé.
        </Col>



        {/* <Col
          span={7}
          xl={4}
          style={{
            textAlign: 'center',
            borderLeft: '1px solid #7D7B81',
            margin: '5px 0',
            padding: '0 10px',
          }}
        >
          <div
            style={{fontSize: '12px', fontWeight: 'bold', lineHeight: '15px'}}
          >
            Vous avez besoin d'informations, de
            <br />
            poser une question, une demande...?
          </div>
          <br />
          <Button
            className="visible-sm"
            type="primary"
            style={{textTransform: 'uppercase'}}
          >
            <Link to="/contact">Contact</Link>
          </Button>
          <Button
            className="visible-xl"
            size="large"
            type="primary"
            style={{textTransform: 'uppercase'}}
          >
            <Link to="/contact">Contactez-nous</Link>
          </Button>
        </Col> */}
      </Row>
    </AntFooter>
  );
};

export default Footer;
