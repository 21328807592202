import {Typography} from 'antd';
import React, {FC} from 'react';
import FileTable from './FileTable';

const {Title} = Typography;

const Sales: FC = () => (
  <>
    <Title level={3} style={{textAlign: 'left'}}>Promotions</Title>
    <FileTable folder="promotions" />
  </>
);

export default Sales;
