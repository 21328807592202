import {Alert, Button, Form, Input} from 'antd';
import React, {FC, useContext, useState} from 'react';
import {useSetUserPassword} from '../api';
import {getApiExceptionData, isApiException} from '../api-helpers';
import Pitch from './Home/Pitch';
import {SessionContext} from './Session';

const printableErrors: {[key: string]: string} = {
  'Missing user session': 'Session utilisateur a expiré',
  'Wrong old password': 'Le mot de passe précédent est invalide',
  'Failed to fetch: Failed to fetch': 'Impossible de se connecter au serveur',
};

export const getErrorString = (error: any) => {
  let message = '';
  if (isApiException(error)) {
    const data = getApiExceptionData(error);
    message = data.message;
  } else {
    message = error.message;
  }
  return printableErrors[message] ?? message;
};

const layout = {
  labelCol: {span: 12},
  wrapperCol: {span: 16},
};
const tailLayout = {
  wrapperCol: {offset: 12, span: 16},
};

interface SettingsFormValues {
  oldPassword: string;
  newPassword: string;
}

const Settings: FC = () => {
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [session, setSession] = useContext(SessionContext);
  const {mutate: setUserPassword, loading} = useSetUserPassword({});

  const onFinish = async ({oldPassword, newPassword}: SettingsFormValues) => {
    try {
      await setUserPassword({
        oldPassword,
        newPassword,
      });
      setError('');
      setSuccess('Votre mot de passe a bien été changé');
    } catch (error) {
      setError(getErrorString(error));
      setSuccess('');
    }
  };

  return (
    <div>
      {/* <Title level={3} style={{textAlign: 'left'}}>
        Paramètres
      </Title>*/}
      <Pitch
        background="Mon espace"
        foreground="Paramètres | Mise à jour de mot de passe"
      />
      <div>
        {error ? (
          <Alert
            message="Erreur de connexion"
            description={error}
            type="error"
            closable
            style={{marginBottom: 20, width: '100%'}}
            onClose={() => setError('')}
          />
        ) : null}
        {success ? (
          <Alert
            message="Succès"
            description={success}
            type="success"
            closable
            style={{marginBottom: 20, width: '100%'}}
            onClose={() => setSuccess('')}
          />
        ) : null}

        <div
          style={{
            marginTop: '20px',
            textAlign: 'center',
          }}
        >
          <Form<SettingsFormValues>
            {...layout}
            className="settings-form"
            name="basic"
            initialValues={{remember: true}}
            onFinish={onFinish}
          >
            <Form.Item
              label="Identifiant"
              name="username"
              initialValue={session?.user.identifiant}
              className="disabled-form-item"
            >
              <Input disabled className="input-transparent" />
            </Form.Item>

            <Form.Item
              label="Ancien mot de passe"
              name="oldPassword"
              rules={[
                {
                  required: true,
                  message: 'Veuillez entrer votre ancien mot de passe',
                },
              ]}
            >
              <Input.Password className="input-transparent" />
            </Form.Item>
            <Form.Item
              label="Nouveau mot de passe"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: 'Veuillez entrer votre nouveau mot de passe',
                },
              ]}
            >
              <Input.Password className="input-transparent" />
            </Form.Item>
            <Form.Item
              label="Confirmation du nouveau mot de passe"
              name="newPassword2"
              rules={[
                {
                  required: true,
                  message:
                    'Veuillez entrer la confirmation de votre nouveau mot de passe',
                },
                ({getFieldValue}) => ({
                  validator(_rule, value) {
                    console.log(getFieldValue('newPassword'), value);
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'Les nouveaux mot de passe ne correspondent pas',
                    );
                  },
                }),
              ]}
            >
              <Input.Password className="input-transparent" />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{textTransform: 'uppercase'}}
                loading={loading}
              >
                Mettre à jour
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Settings;
