import React, { FC, useCallback, useContext, useState } from 'react';
import { RestfulProvider } from 'restful-react';
import { UserList } from '../api';
import { useAsync } from '../hooks/useAsync';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { fetchApi, newApiUrl } from '../util';

const SESSION_KEY = 'session';

export interface Session {
  token: string;
  expires?: string /** @desc ISO 8601 string */;
  remember: boolean;
  user: UserList;
}

export const SessionContext = React.createContext<
  [
    Session | undefined,
    React.Dispatch<React.SetStateAction<Session | undefined>>,
  ]
>([undefined, () => {}]);

export const SessionProvider: FC = (props) => {
  const [sessionStored, setSessionStored] = useLocalStorage(SESSION_KEY);
  const [session, setSession] = useState<Session | undefined>(
    sessionStored ? JSON.parse(sessionStored) : undefined,
  );

  const req = useCallback(async () => {
    if (!session) return;

    const url = newApiUrl(`/session`);
    const res = await fetchApi({session, url});
    return (await res.json()) as {token: string};
  }, [session]);
  const {value} = useAsync(req);

  if (session && value && !value.token) {
    console.log('log out');
    setSessionStored(undefined);
    setSession(undefined);
    // @ts-ignore
    window.location = `/login?redirect=${encodeURIComponent(window.location.pathname)}`;
  }

  return (
    <SessionContext.Provider
      value={[
        session,
        (session) => {
          setSessionStored(session ? JSON.stringify(session) : undefined);
          setSession(session);
        },
      ]}
    >
      <RestfulProvider
        base={process.env.REACT_APP_API_HOST ?? ''}
        requestOptions={
          !!session
            ? {headers: {Authorization: `Bearer ${session.token}`}}
            : undefined
        }
      >
        {props.children}
      </RestfulProvider>
    </SessionContext.Provider>
  );
};

export const useUser = (): UserList | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [session] = useContext(SessionContext);
  return session?.user ?? undefined;
};
