import {useEffect, useState} from 'react';

/** hook that syncs a variable to local storage. Listens to "storage" event */
export const useLocalStorage = (
  key: string,
  defaultValue?: string,
): [string | undefined, (value: string | undefined) => void] => {
  const [value, setValue] = useState<string | undefined>(
    localStorage.getItem(key) ?? defaultValue,
  );

  useEffect(() => {
    const onChange = (event: any) => {
      if (event.key !== key) {
        return;
      }
      setValue(event.newValue);
    };

    window.addEventListener('storage', onChange, false);
    return () => window.removeEventListener('storage', onChange);
  }, [key]);

  return [
    value,
    (value: string | undefined) => {
      if (value === undefined) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, value);
      }
      setValue(value);
    },
  ];
};
