import {useEffect, useState, useCallback} from 'react';
import {useIsMounted} from './useIsMounted';

export enum AsyncStatus {
  IDLE = 1,
  PENDING,
  SUCCESS,
  ERROR,
}

export type AsyncCallback<V> = () => Promise<V>;

/** hook that keeps track of the execution of an async function.
 * @param {function} func async function to track
 * @param {boolean} immediate start async function on mount automatically
 */
export function useAsync<V>(func: AsyncCallback<V>, immediate = true) {
  const [status, setStatus] = useState<AsyncStatus>(
    immediate ? AsyncStatus.PENDING : AsyncStatus.IDLE,
  );
  const [value, setValue] = useState<V | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const isMounted = useIsMounted();

  const execute = useCallback(async () => {
    setStatus(AsyncStatus.PENDING);
    setValue(null);
    setError(null);

    try {
      const result = await func();
      if (isMounted()) {
        setValue(result);
        setStatus(AsyncStatus.SUCCESS);
      }
    } catch (error) {
      if (isMounted()) {
        setError(error);
        setStatus(AsyncStatus.ERROR);
      }
    }
  }, [func]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return {
    execute,
    status,
    value,
    error,
    loading: status === AsyncStatus.PENDING,
  };
}

/** copy of useAsync hook that accepts an array of functions instead of 1 */
export function useAsyncAll<V>(funcs: AsyncCallback<V>[], immediate = true) {
  const [status, setStatus] = useState<AsyncStatus>(
    immediate ? AsyncStatus.PENDING : AsyncStatus.IDLE,
  );
  const [value, setValue] = useState<V[] | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const isMounted = useIsMounted();

  const execute = useCallback(async () => {
    setStatus(AsyncStatus.PENDING);
    setValue(null);
    setError(null);

    try {
      const result = await Promise.all(funcs.map((func) => func()));
      if (isMounted()) {
        setValue(result);
        setStatus(AsyncStatus.SUCCESS);
      }
    } catch (error) {
      if (isMounted()) {
        setError(error);
        setStatus(AsyncStatus.ERROR);
      }
    }
  }, [funcs, isMounted]);

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return {
    execute,
    status,
    value,
    error,
    loading: status === AsyncStatus.PENDING,
  };
}
