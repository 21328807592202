import {FC} from 'react';

const Pitch: FC<{background?: string; foreground?: string}> = ({
  background = 'Bienvenue',
  foreground = "dans l'espace destiné aux audioprothésistes.",
}) => (
  <>
    <div
      className="pitch"
      style={{
        textAlign: 'center',
        margin: '5px 0',
        height: '125px',
        position: 'relative',
        top: '-15px',
        maxWidth: '100vw',
      }}
    >
      <div className="background">{background}</div>
      <div className="foreground">{foreground}</div>
    </div>
  </>
);

export default Pitch;
